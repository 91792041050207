import React from "react";
import { Link } from "react-router-dom";
import { DonationTable } from "../components/DonationTable";
import { DonationCharts } from "../components/DonationCharts";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";
import { useDonations } from "../utils/useDonations";

export function Track() {
  const donations = useDonations();
  return (
    <>
      <Header title="Track"></Header>
      <PageLayout>
        <ul>
          <li>
            <Link to="/donation/create">Create donation</Link>
          </li>
          <li>
            <Link to="/goal/create">Set goal</Link>
          </li>
        </ul>
        {donations && <DonationCharts donations={donations} />}
        {donations && <DonationTable donations={donations} />}
      </PageLayout>
    </>
  );
}
