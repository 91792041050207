import { useFirestore, useFirestoreCollectionData, useAuth } from "reactfire";
import { GoalType, Goal } from "../types/goal";

export function useGetGoal({ type }: { type: GoalType }) {
  const auth = useAuth();
  const uid = auth.currentUser?.uid;
  const goalsRef = useFirestore()
    .collection("goals")
    .where("owner", "==", uid || null)
    .where("type", "==", type)
    .limit(1);

  const goals = useFirestoreCollectionData(goalsRef) as Goal[];
  if (!uid || !goals[0]) {
    return null;
  }
  return goals[0];
}
