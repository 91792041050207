import { useEffect, useState } from "react";
import { Donation } from "../types/donation";
import { convertDenominationTotals, DEFAULT_DENOMINATION } from "./currency";

const MS_IN_DAY = 1000 * 60 * 60 * 24;

export type DonationDatum = {
  date: Date;
  totals: {
    [denomination: string]: number;
  };
  totalsByCharity: {
    [charity: string]: { [denomination: string]: number };
  };
  amountMonthly: {
    [denomination: string]: number;
  };
};

export type ChartDatum = {
  date: Date;
  total: number;
  amountMonthly: number;
  totalsByCharity: {
    [charity: string]: number;
  };
};

export function useDonationData(donations: Donation[]) {
  const [chartData, setChartData] = useState<ChartDatum[]>([]);

  useEffect(() => {
    if (donations.length === 0) {
      return;
    }
    const firstDonation = donations.reduce((a, b) =>
      new Date(a.startDate).getTime() < new Date(b.startDate).getTime() ? a : b
    );

    const data: DonationDatum[] = [];
    const totals: { [denomination: string]: number } = {};
    const totalsByCharity: {
      [charity: string]: { [denomination: string]: number };
    } = {};

    for (
      let d = new Date(firstDonation.startDate).getTime();
      d < new Date().getTime();
      d += MS_IN_DAY
    ) {
      let dateHadDonation = false;
      const amountMonthly: { [denomination: string]: number } = {};

      for (let donation of donations) {
        const startTime = new Date(donation.startDate).getTime();
        const endTime = new Date(donation.endDate || new Date()).getTime();
        if (d <= endTime && d >= startTime) {
          if (
            new Date(donation.startDate).getDate() === new Date(d).getDate() &&
            (donation.includesStart || startTime !== d)
          ) {
            const denomination = donation.denomination || DEFAULT_DENOMINATION;

            totals[denomination] = totals[denomination] || 0;
            totals[denomination] =
              totals[denomination] + donation.amountMonthly;

            totalsByCharity[donation.charity] =
              totalsByCharity[donation.charity] || {};
            totalsByCharity[donation.charity][denomination] =
              totalsByCharity[donation.charity][denomination] || 0;
            totalsByCharity[donation.charity][denomination] =
              totalsByCharity[donation.charity][denomination] +
              donation.amountMonthly;

            amountMonthly[denomination] = amountMonthly[denomination] || 0;
            amountMonthly[denomination] =
              amountMonthly[denomination] + donation.amountMonthly;

            dateHadDonation = true;
          }
        }
      }
      if (dateHadDonation) {
        data.push({
          date: new Date(d),
          totals: { ...totals },
          totalsByCharity: { ...totalsByCharity },
          amountMonthly: { ...amountMonthly },
        });
      }
    }

    convertDenominationTotals(data).then((convertedTotal) => {
      setChartData(convertedTotal);
    });
  }, [donations]);

  return chartData;
}
