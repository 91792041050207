import { useFirestore, useAuth } from "reactfire";
import { Goal, GoalDocument } from "../types/goal";

export function useSetGoal() {
  const firestore = useFirestore();
  const auth = useAuth();
  return function setGoal(goal: Omit<Goal, "id">) {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return Promise.reject();
    }
    const id = `${uid}:${goal.type}`;
    const goalDocument: GoalDocument = {
      ...Object.fromEntries(Object.entries(goal).filter(([_k, v]) => !!v)),
      id,
      owner: uid,
    } as GoalDocument;
    return firestore.collection("goals").doc(id).set(goalDocument);
  };
}
