import * as React from "react";
import { Table } from "semantic-ui-react";
import useDimensions from "react-use-dimensions";
import { Donation } from "../types/donation";
import { useDeleteDonation } from "../utils/useDeleteDonation";
import { styled } from "linaria/react";
import { Link } from "react-router-dom";

const Reference = styled.div`
  max-width: calc(var(--grid) * 20);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DonationTableWrapper = styled.div`
  margin-top: calc(var(--grid) * 2);
`;

export function DonationTable({ donations }: { donations: Donation[] }) {
  const deleteDonation = useDeleteDonation();
  const [ref, { width }] = useDimensions();
  return (
    <DonationTableWrapper ref={ref}>
      <h2>Donation entries</h2>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Charity</Table.HeaderCell>
            <Table.HeaderCell>Amount monthly</Table.HeaderCell>
            <Table.HeaderCell>Start date</Table.HeaderCell>
            <Table.HeaderCell>End date</Table.HeaderCell>
            {width > 1200 ? (
              <>
                <Table.HeaderCell>Denomination</Table.HeaderCell>
                <Table.HeaderCell>Includes start date?</Table.HeaderCell>
                <Table.HeaderCell>Reference</Table.HeaderCell>
                <Table.HeaderCell>Notes</Table.HeaderCell>
                <Table.HeaderCell>Is current?</Table.HeaderCell>
              </>
            ) : null}

            <Table.HeaderCell>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {donations.map((donation) => (
            <Table.Row>
              <Table.Cell>{donation.charity}</Table.Cell>
              <Table.Cell>{donation.amountMonthly}</Table.Cell>
              <Table.Cell>{donation.startDate}</Table.Cell>
              <Table.Cell>{donation.endDate}</Table.Cell>

              {width > 1200 ? (
                <>
                  <Table.Cell>{donation.denomination}</Table.Cell>
                  <Table.Cell>{donation.includesStart ? "✅" : ""}</Table.Cell>
                  <Table.Cell>
                    {<Reference>{donation.reference}</Reference>}
                  </Table.Cell>
                  <Table.Cell>{donation.notes}</Table.Cell>
                  <Table.Cell>{donation.isCurrent ? "✅" : ""}</Table.Cell>
                </>
              ) : null}
              <Table.Cell>
                <button onClick={() => deleteDonation(donation.id)}>
                  Delete
                </button>
                <Link to={`/donation/${donation.id}/edit`}>Edit</Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </DonationTableWrapper>
  );
}
