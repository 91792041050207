import React from "react";
import { Link } from "react-router-dom";
import { SetDonationForm } from "../components/SetDonationForm";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";

export function CreateDonation() {
  return (
    <>
      <Header title="Create donation" />
      <PageLayout>
        <Link to="/track">&lt; Track</Link>

        <h2>Create donation</h2>
        <SetDonationForm />
      </PageLayout>
    </>
  );
}
