import React, { FunctionComponent } from "react";
import { styled } from "linaria/react";

const Page = styled.main`
  padding: 0 calc(3 * var(--grid));
`;

export const PageLayout: FunctionComponent = ({ children }) => {
  return <Page>{children}</Page>;
};
