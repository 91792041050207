import React from "react";
import { Link } from "react-router-dom";
import { SetGoalForm } from "../components/SetGoalForm";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";

export function SetGoal() {
  return (
    <>
      <Header title="Set goal" />
      <PageLayout>
        <Link to="/track">&lt; Track</Link>
        <SetGoalForm />
      </PageLayout>
    </>
  );
}
