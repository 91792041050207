import { styled } from "linaria/react";
import React from "react";
import { Form, Field } from "react-final-form";
import { useSetDonation } from "../utils/useSetDonation";
import { Donation } from "../types/donation";

const FieldWrapper = styled.fieldset`
  display: flex;
  border: none;
  padding: 0;
  flex-direction: column;
  padding: 0 0 var(--grid) 0;
  max-width: calc(var(--grid) * 40);
`;

const CheckboxFieldWrapper = styled(FieldWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;

  input[type="checkbox"] {
    margin: 0.5rem;
  }
`;

export function SetDonationForm({ donation }: { donation?: Donation }) {
  const setDonation = useSetDonation();
  return (
    <Form
      onSubmit={async (data) => {
        setDonation({
          charity: data.charity,
          amountMonthly: data.amountMonthly,
          denomination: data.denomination,
          startDate: data.startDate,
          endDate: data.endDate,
          includesStart: data.includesStart,
          reference: data.reference,
          notes: data.notes,
          id: donation?.id,
        });
      }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <FieldWrapper>
            <label htmlFor="charity">Charity</label>
            <Field
              id="charity"
              name="charity"
              component="input"
              placeholder="Charity"
              initialValue={donation?.charity}
            />
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="amountMonthly">Amount monthly</label>
            <Field
              id="amountMonthly"
              name="amountMonthly"
              component="input"
              type="number"
              parse={(s) => parseInt(s, 10)}
              initialValue={donation?.amountMonthly}
            />
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="denomination">Denomination</label>
            <Field
              id="denomination"
              name="denomination"
              component="select"
              initialValue={donation?.denomination ?? "USD"}
            >
              <option value="AUD">AUD</option>
              <option value="USD">USD</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="startDate">Start date</label>
            <Field
              id="startDate"
              name="startDate"
              component="input"
              type="date"
              initialValue={donation?.startDate}
            />
          </FieldWrapper>
          <CheckboxFieldWrapper>
            <label htmlFor="includesStart">Include start date?</label>
            <Field
              id="includesStart"
              name="includesStart"
              component="input"
              type="checkbox"
              initialValue={donation?.includesStart}
            />
          </CheckboxFieldWrapper>
          <CheckboxFieldWrapper>
            <label htmlFor="isCurrent">Is donation current?</label>
            <Field
              id="isCurrent"
              name="isCurrent"
              component="input"
              type="checkbox"
              initialValue={donation?.isCurrent}
            />
          </CheckboxFieldWrapper>
          <FieldWrapper>
            <label htmlFor="endDate">End date</label>
            <Field
              disabled={form.getFieldState("isCurrent")?.value}
              id="endDate"
              name="endDate"
              component="input"
              type="date"
              initialValue={donation?.endDate}
            />
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="reference">Reference</label>
            <Field
              id="reference"
              name="reference"
              component="input"
              placeholder="Reference"
              initialValue={donation?.reference}
            />
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="notes">Notes</label>
            <Field
              id="notes"
              name="notes"
              component="input"
              placeholder="Notes"
              initialValue={donation?.notes}
            />
          </FieldWrapper>
          <button type="submit">Submit</button>
        </form>
      )}
    />
  );
}
