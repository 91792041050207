import * as React from "react";
import useDimensions from "react-use-dimensions";
import { Donation } from "../types/donation";
import { CumulativeDonationChart } from "./charts/CumulativeDonationChart";
import { useDonationData } from "../utils/useDonationData";
import { GoalTracker } from "./charts/GoalTracker";
import { ErrorBoundary } from "./ErrorBoundary";
import { TotalByCharity } from "./charts/TotalByCharity";

export function DonationCharts({ donations }: { donations: Donation[] }) {
  const chartData = useDonationData(donations);

  const [ref, { width }] = useDimensions();

  const chartWidth = Math.min(width === 0 ? window.innerWidth : width, 1200);

  return (
    <div ref={ref}>
      <CumulativeDonationChart width={chartWidth} chartData={chartData} />
      <ErrorBoundary>
        <GoalTracker width={chartWidth} chartData={chartData} />
      </ErrorBoundary>
      <TotalByCharity chartData={chartData} />
    </div>
  );
}
