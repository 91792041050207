import { useFirestore, useAuth } from "reactfire";

export function useDeleteDonation() {
  const firestore = useFirestore();
  const auth = useAuth();
  return function deleteDonation(id: string) {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return Promise.reject();
    }
    return firestore.collection("donations").doc(id).delete();
  };
}
