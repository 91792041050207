import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../components/Header";
export function Home() {
  return (
    <>
      <Header title="Home" />
      <Link to="/track">Track</Link>
    </>
  );
}
