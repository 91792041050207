import { DonationDatum } from "./useDonationData";

export const DEFAULT_DENOMINATION = "USD";

export function formatDate(date: Date) {
  return date.toISOString().split("T")[0];
}

export async function convertDenominationTotals(
  data: DonationDatum[],
  into: string = "USD"
) {
  const symbols: { [key: string]: string } = {};
  data.forEach(({ totals }) => {
    Object.keys(totals).forEach((symbol) => {
      symbols[symbol || "USD"] = symbol || "USD";
    });
  });

  const firstDate = formatDate(data[0].date);
  const lastDate = formatDate(data[data.length - 1].date);
  const results: {
    base: string;
    end_at: string;
    rates: {
      [date: string]: {
        [symbol: string]: number;
      };
    };
  } = await fetch(
    `https://api.exchangeratesapi.io/history?base=${into}&symbols=${Object.keys(
      symbols
    ).join(",")}&start_at=${firstDate}&end_at=${lastDate}`
  ).then((r) => r.json());

  const convertedTotals: {
    date: Date;
    total: number;
    amountMonthly: number;
    totalsByCharity: { [charity: string]: number };
  }[] = [];

  const exchangeRateEntries = Object.entries(results.rates);
  for (let dataPoint of data) {
    if (exchangeRateEntries.length === 0) {
      continue;
    }
    let exchangeRate = exchangeRateEntries[0][1];
    let closestDate = exchangeRateEntries[0][0];

    for (let [date, rate] of Object.entries(results.rates)) {
      if (
        Math.abs(dataPoint.date.getTime() - new Date(date).getTime()) <
        Math.abs(new Date(closestDate).getTime() - new Date(date).getTime())
      ) {
        exchangeRate = rate;
        closestDate = date;
        break;
      }
    }

    convertedTotals.push({
      date: dataPoint.date,
      total: Object.entries(dataPoint.totals)
        .map(([symbol, amount]) => amount / exchangeRate[symbol])
        .reduce((a, b) => a + b, 0),
      amountMonthly: Object.entries(dataPoint.amountMonthly)
        .map(([symbol, amount]) => amount / exchangeRate[symbol])
        .reduce((a, b) => a + b, 0),
      totalsByCharity: Object.fromEntries(
        Object.entries(dataPoint.totalsByCharity).map(([charity, totals]) => [
          charity,
          Object.entries(totals)
            .map(([symbol, amount]) => amount / exchangeRate[symbol])
            .reduce((a, b) => a + b, 0),
        ])
      ),
    });
  }
  return convertedTotals;
}
