import * as React from "react";
import { styled } from "linaria/react";
import { Link } from "react-router-dom";

const HeaderWrapper = styled.nav`
  color: var(--headline);
  min-height: calc(var(--grid) * 4);
  padding: var(--grid);
  align-items: center;
  display: flex;
  margin-bottom: calc(var(--grid) * 2);
  min-height: calc(var(--grid) * 4);
  font-weight: 400;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 3px 4px 1px #ccc;
`;

const Title = styled.h1`
  border-bottom: 3px solid currentColor;
`;

const SubTitle = styled.span`
  margin-top: calc(var(--grid) * 1);
  font-weight: 500;
`;

export function Header({ title }: { title?: string }) {
  return (
    <HeaderWrapper>
      <Link to="/">
        <Title>∞&nbsp; Give Slow</Title>
      </Link>
      <SubTitle>{title}</SubTitle>
    </HeaderWrapper>
  );
}
