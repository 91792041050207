import { useFirestore, useFirestoreCollectionData, useAuth } from "reactfire";
import { Donation } from "../types/donation";

export function useDonations() {
  const auth = useAuth();
  const uid = auth.currentUser?.uid;
  const donationsRef = useFirestore()
    .collection("donations")
    .where("owner", "==", uid || null);

  const donations = useFirestoreCollectionData(donationsRef) as Donation[];

  if (!uid) {
    return null;
  }
  return donations;
}
