import { createBrowserHistory } from "history";
import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire";

import { LoginRedirect } from "./LoginRedirect";
import { firebaseConfig } from "../config/firebase.config";
import { Track } from "./Track";
import { Home } from "./Home";
import { Login } from "./Login";
import { CreateDonation } from "./CreateDonation";
import { SetGoal } from "./SetGoal";
import { EditDonation } from "./EditDonation";

export const history = createBrowserHistory();

export function Routes(): JSX.Element | null {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <SuspenseWithPerf fallback={null} traceId={"load-routes"}>
        <Router history={history}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/login" exact>
              <Login />
            </Route>
            <Route path="/track">
              <LoginRedirect>
                <Track />
              </LoginRedirect>
            </Route>
            <Route path="/donation/create">
              <LoginRedirect>
                <CreateDonation />
              </LoginRedirect>
            </Route>
            <Route
              path="/donation/:id/edit"
              render={(routeProps) => (
                <LoginRedirect>
                  <EditDonation {...routeProps} />
                </LoginRedirect>
              )}
            ></Route>
            <Route path="/goal/set">
              <LoginRedirect>
                <SetGoal />
              </LoginRedirect>
            </Route>
          </Switch>
        </Router>
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  );
}
