import { useFirestore, useAuth } from "reactfire";
import { Donation, DonationDocument } from "../types/donation";
import { v4 as uuidv4 } from "uuid";

export function useSetDonation() {
  const firestore = useFirestore();
  const auth = useAuth();
  return function setDonation(
    donation: Omit<Donation, "id"> & { id?: string }
  ) {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return Promise.reject();
    }
    const id = donation.id || uuidv4();
    const donationDocument: DonationDocument = {
      ...Object.fromEntries(Object.entries(donation).filter(([_k, v]) => !!v)),
      id,
      owner: uid,
    } as DonationDocument;
    return firestore.collection("donations").doc(id).set(donationDocument);
  };
}
