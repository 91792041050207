import React from "react";
import { Link, RouteChildrenProps } from "react-router-dom";
import { SetDonationForm } from "../components/SetDonationForm";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";
import { useDonations } from "../utils/useDonations";

export function EditDonation({ match }: RouteChildrenProps<{ id: string }>) {
  const donationId = match?.params.id;
  const donations = useDonations();
  if (!donationId) {
    return null;
  }
  if (!donations) {
    return (
      <>
        <Header title="Edit donation" />
        <PageLayout>
          <Link to="/track">&lt; Track</Link>
          <h2>Edit donation</h2>
          Loading...
        </PageLayout>
      </>
    );
  }
  return (
    <>
      <Header title="Edit donation" />
      <PageLayout>
        <Link to="/track">&lt; Track</Link>
        <h2>Edit donation</h2>
        <SetDonationForm
          donation={donations.find((d) => d.id === donationId)}
        />
      </PageLayout>
    </>
  );
}
