import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SuspenseWithPerf, useAuth } from "reactfire";
import firebase from "firebase";
import { Header } from "../components/Header";

type LoginParams = {
  continue: string;
};

export function Login(): JSX.Element | null {
  const history = useHistory();
  const location = useLocation<LoginParams>();
  const cont = new URLSearchParams(location.search).get("continue") || "/";
  const [isCreating, setIsCreating] = useState(false);
  const auth = useAuth();

  return (
    <>
      <SuspenseWithPerf fallback={null} traceId={"load-login"}>
        <Header title="Log in" />
        <h1>Log in</h1>
        <button
          disabled={isCreating}
          onClick={async () => {
            setIsCreating(true);
            try {
              const provider = new firebase.auth.GoogleAuthProvider();
              await auth.signInWithPopup(provider);
              history.replace(cont);
            } catch (e) {
              alert(
                "There was some problem logging in. Check the console for details. Try again later?"
              );
              console.error(e);
            }
            setIsCreating(false);
          }}
        >
          {isCreating ? "Logging in" : "Log in with Google"}
        </button>
      </SuspenseWithPerf>
    </>
  );
}
