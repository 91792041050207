import * as React from "react";
import { ChartDatum } from "../../utils/useDonationData";
import { Table } from "semantic-ui-react";

export function TotalByCharity({ chartData }: { chartData: ChartDatum[] }) {
  if (chartData.length === 0) {
    return null;
  }

  const current = chartData[chartData.length - 1].totalsByCharity;

  const data = Object.entries(current)
    .map(([charity, amount]) => ({
      x: charity,
      y: amount,
    }))
    .sort((a, b) => b.y - a.y);

  return (
    <>
      {chartData.length > 1 && (
        <>
          <h2>Total by charity</h2>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Charity</Table.HeaderCell>
                <Table.HeaderCell>Total amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((datum) => (
                <Table.Row>
                  <Table.Cell>{datum.x}</Table.Cell>
                  <Table.Cell>{datum.y}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
}
