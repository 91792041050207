import * as React from "react";
import { useGetGoal } from "../../utils/useGetGoal";
import { ChartDatum } from "../../utils/useDonationData";
import { VictoryStack, VictoryBar } from "victory";

function formatCurrency(x: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(x);
}

export function GoalTracker({
  chartData,
  width,
}: {
  chartData: ChartDatum[];
  width: number;
}) {
  const goal = useGetGoal({ type: "primary" });
  if (chartData.length === 0) {
    return null;
  }
  if (!goal) {
    return (
      <p>Total: {formatCurrency(chartData[chartData.length - 1].total)}</p>
    );
  }
  const current = chartData[chartData.length - 1].total;
  const target = goal?.amount;

  return (
    <>
      {chartData.length > 1 && (
        <>
          <h2>Progress</h2>
          <p>Goal: {formatCurrency(target)}</p>
          <p>Total so far: {formatCurrency(current)}</p>
          <p>Progress: {`${Math.round((current / target) * 100)}%`}</p>
          <VictoryStack
            colorScale={["tomato", "black"]}
            height={50}
            width={width}
          >
            <VictoryBar
              horizontal
              data={[{ x: "Progress", y: current / target }]}
            />
            <VictoryBar horizontal data={[{ x: "Progress", y: 1 }]} />
          </VictoryStack>
        </>
      )}
    </>
  );
}
