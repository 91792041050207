import { styled } from "linaria/react";
import * as React from "react";
import { useState, useEffect } from "react";

import {
  DomainPropType,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryZoomContainer,
} from "victory";
import { theme } from "../charts/theme";
import { ChartDatum } from "../../utils/useDonationData";

const ChartWrapper = styled.div`
  height: calc(var(--grid) * 50);
  margin: var(--grid);
`;

export function CumulativeDonationChart({
  chartData,
  width,
}: {
  chartData: ChartDatum[];
  width: number;
}) {
  const [zoomDomain, setZoomDomain] = useState<DomainPropType>({
    x: [new Date(0), new Date()],
  });

  useEffect(() => {
    if (chartData.length > 0) {
      setZoomDomain({
        x: [chartData[0].date, chartData[chartData.length - 1].date],
      });
    }
  }, [chartData]);

  return (
    <>
      <h2>Cumulative donations</h2>
      <ChartWrapper>
        <VictoryChart
          width={width}
          height={500}
          theme={theme}
          scale={{ x: "time" }}
          containerComponent={
            <VictoryZoomContainer
              zoomDimension="x"
              zoomDomain={zoomDomain as { x: [number, number] }}
              onZoomDomainChange={(z) => {
                setZoomDomain(z);
              }}
              responsive
            />
          }
        >
          <VictoryLine
            interpolation="linear"
            style={{
              data: { stroke: "#c43a31" },
              parent: { border: "1px solid #ccc" },
            }}
            data={chartData.map((datum) => ({
              x: datum.date,
              y: datum.total,
            }))}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(t) => `${Math.round(t / 1000)}k`}
            label="Amount ($)"
            tickLabelComponent={<VictoryLabel dx={40} />}
          />
          <VictoryAxis
            label="Date"
            axisLabelComponent={<VictoryLabel dy={30} />}
          />
        </VictoryChart>
      </ChartWrapper>
      {/* <h2>Amount monthly</h2>
      <ChartWrapper>
        <VictoryChart
          width={width}
          height={300}
          theme={theme}
          scale={{ x: "time" }}
          containerComponent={
            <VictoryZoomContainer
              zoomDimension="x"
              zoomDomain={zoomDomain as { x: [number, number] }}
              onZoomDomainChange={(z) => {
                setZoomDomain(z);
              }}
              responsive
            />
          }
        >
          <VictoryLine
            interpolation="linear"
            style={{
              data: { stroke: "#c43a31" },
              parent: { border: "1px solid #ccc" },
            }}
            data={chartData.map((datum) => ({
              x: datum.date,
              y: datum.amountMonthly,
            }))}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(t) => `${Math.round(t / 1000)}k`}
            label="Amount ($)"
            tickLabelComponent={<VictoryLabel dx={40} />}
          />
          <VictoryAxis
            label="Date"
            axisLabelComponent={<VictoryLabel dy={30} />}
          />
        </VictoryChart>
      </ChartWrapper> */}
    </>
  );
}
