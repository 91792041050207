import { styled } from "linaria/react";
import React from "react";
import { Form, Field } from "react-final-form";
import { useSetGoal } from "../utils/useSetGoal";

const FieldWrapper = styled.fieldset`
  display: flex;
  border: none;
  padding: 0;
  flex-direction: column;
  padding: 0 0 var(--grid) 0;
  max-width: calc(var(--grid) * 40);
`;

export function SetGoalForm() {
  const setGoal = useSetGoal();
  return (
    <Form
      onSubmit={async (data) => {
        setGoal({
          amount: data.amount,
          denomination: data.denomination,
          type: data.type,
        });
      }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <h2>Set goal</h2>
          <FieldWrapper>
            <label htmlFor="amount">Amount</label>
            <Field
              id="amount"
              name="amount"
              component="input"
              placeholder="0"
            />
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="denomination">Denomination</label>
            <Field
              id="denomination"
              name="denomination"
              component="select"
              initialValue="USD"
            >
              <option value="AUD">AUD</option>
              <option value="USD">USD</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="type">Type</label>
            <Field
              id="type"
              name="type"
              component="select"
              initialValue="primary"
            >
              <option value="primary">Primary</option>
            </Field>
          </FieldWrapper>
          <button type="submit">Submit</button>
        </form>
      )}
    />
  );
}
